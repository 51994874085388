import React, { useState, useCallback, useEffect } from 'react';
import { Grid, Paper, Typography, Button, Box, Snackbar, Alert } from '@mui/material';
import { utils } from 'ethers';
import Column from './Column';
import TaskForm from './TaskForm';

/**
 * KanbanBoard Component
 * 
 * This component represents the main Kanban board interface.
 * It manages the state of tasks, handles task creation, editing, and movement between columns.
 * 
 * @param {Object} props - Component props
 * @param {Object} props.provider - Ethers.js provider for blockchain interactions
 * @param {string} props.walletAddress - Connected wallet address
 */
const KanbanBoard = ({ provider, walletAddress }) => {
  // State for tasks, task form visibility, current task being edited, and error messages
  const [tasks, setTasks] = useState([]);
  const [isTaskFormOpen, setIsTaskFormOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [error, setError] = useState(null);

  // Define the columns for the Kanban board
  const columns = [
    { title: 'To Do', status: 'todo' },
    { title: 'In Progress', status: 'in-progress' },
    { title: 'Done', status: 'done' }
  ];

  /**
   * Perform ENS lookup for an address
   * @param {string} address - Ethereum address to look up
   * @returns {Promise<string>} The ENS name if found, or the original address
   */
  const lookupENS = useCallback(async (address) => {
    if (!provider) return address;
    try {
      const ens = await provider.lookupAddress(address);
      return ens || address;
    } catch (error) {
      console.error("Error looking up ENS name:", error);
      return address;
    }
  }, [provider]);

  /**
   * Resolve an ENS name to an Ethereum address
   * @param {string} name - ENS name to resolve
   * @returns {Promise<string|null>} The Ethereum address if resolved, or null
   */
  const resolveENS = useCallback(async (name) => {
    if (!provider) return null;
    try {
      return await provider.resolveName(name);
    } catch (error) {
      console.error("Error resolving ENS name:", error);
      return null;
    }
  }, [provider]);

  /**
   * Handle saving a task (create or update)
   * @param {Object} task - The task to be saved
   */
  const handleSaveTask = useCallback(async (task) => {
    try {
      let updatedTask = { ...task };

      // Perform reverse lookup for the creator's address
      updatedTask.creatorENS = await lookupENS(walletAddress);

      // Resolve the assignee's ENS name to an address if it's an ENS name
      if (utils.isAddress(task.assignee)) {
        updatedTask.assigneeENS = await lookupENS(task.assignee);
      } else {
        const resolvedAddress = await resolveENS(task.assignee);
        if (resolvedAddress) {
          updatedTask.assignee = resolvedAddress;
          updatedTask.assigneeENS = task.assignee; // The original input was an ENS name
        } else {
          setError("Invalid ENS name or Ethereum address for assignee");
          return;
        }
      }

      // Update existing task or add new task
      if (task.id) {
        setTasks(prevTasks => prevTasks.map(t => t.id === task.id ? updatedTask : t));
      } else {
        setTasks(prevTasks => [...prevTasks, { ...updatedTask, id: Date.now().toString() }]);
      }

      setIsTaskFormOpen(false);
      setCurrentTask(null);
    } catch (err) {
      console.error('Failed to save task:', err);
      setError('Failed to save task. Please try again.');
    }
  }, [walletAddress, lookupENS, resolveENS]);

  /**
   * Handle moving a task between columns
   * @param {string} taskId - The ID of the task to move
   * @param {string} newStatus - The new status of the task
   */
  const handleMoveTask = useCallback((taskId, newStatus) => {
    setTasks(prevTasks => prevTasks.map(task => 
      task.id === taskId ? { ...task, status: newStatus } : task
    ));
  }, []);

  /**
   * Handle editing an existing task
   * @param {Object} task - The task to edit
   */
  const handleEditTask = useCallback((task) => {
    setCurrentTask(task);
    setIsTaskFormOpen(true);
  }, []);

  /**
   * Handle creating a new task
   */
  const handleCreateTask = useCallback(() => {
    setCurrentTask(null);
    setIsTaskFormOpen(true);
  }, []);

  /**
   * Close the error snackbar
   */
  const handleCloseError = useCallback((event, reason) => {
    if (reason === 'clickaway') return;
    setError(null);
  }, []);

  // Load tasks from localStorage on component mount
  useEffect(() => {
    const savedTasks = localStorage.getItem('tasks');
    if (savedTasks) {
      setTasks(JSON.parse(savedTasks));
    }
  }, []);

  // Save tasks to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Kanban Board
      </Typography>
      <Grid container spacing={3}>
        {columns.map(({ title, status }) => (
          <Grid item xs={12} md={4} key={status}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 2, 
                bgcolor: 'background.paper',
                borderTop: 3,
                borderColor: 'primary.main'
              }}
            >
              <Typography variant="h6" gutterBottom color="primary">
                {title}
              </Typography>
              <Column
                status={status}
                tasks={tasks.filter(task => task.status === status)}
                onMoveTask={handleMoveTask}
                onEditTask={handleEditTask}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleCreateTask}>
          Create New Task
        </Button>
      </Box>
      <TaskForm
        open={isTaskFormOpen}
        onClose={() => setIsTaskFormOpen(false)}
        onSave={handleSaveTask}
        task={currentTask}
        walletAddress={walletAddress}
        provider={provider}
      />
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default KanbanBoard;