import React from 'react';
import { Box } from '@mui/material';
import TaskCard from './TaskCard';

const Column = ({ status, tasks, onMoveTask, onEditTask }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const taskId = e.dataTransfer.getData('text/plain');
    onMoveTask(taskId, status);
  };

  return (
    <Box
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      sx={{
        height: '100%',
        minHeight: '300px',
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 2,
      }}
    >
      {tasks.map((task, index) => (
        <TaskCard
          key={task.id}
          task={task}
          index={index}
          onMoveTask={onMoveTask}
          onEditTask={onEditTask}
        />
      ))}
    </Box>
  );
};

export default Column;