import React, { useState, useEffect, useCallback } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Select, 
  Chip, 
  Box, 
  Typography 
} from '@mui/material';
import { utils } from 'ethers';

/**
 * TaskForm Component
 * 
 * This component provides a form for creating and editing tasks in the Koinban application.
 * It includes fields for all task properties, input validation, and ENS resolution for Ethereum addresses.
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.open - Controls the visibility of the dialog
 * @param {function} props.onClose - Function to call when closing the dialog
 * @param {function} props.onSave - Function to call when saving the task
 * @param {Object} props.task - Existing task object for editing (null for new tasks)
 * @param {string} props.walletAddress - Current user's wallet address
 * @param {Object} props.provider - Ethers.js provider for ENS resolution
 */
const TaskForm = ({ open, onClose, onSave, task, walletAddress, provider }) => {
  // State for form fields
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [assignee, setAssignee] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [status, setStatus] = useState('todo');
  const [priority, setPriority] = useState('medium');
  const [loe, setLoe] = useState('medium');
  const [estimatedHours, setEstimatedHours] = useState('');
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');

  // State for form field errors
  const [titleError, setTitleError] = useState('');
  const [assigneeError, setAssigneeError] = useState('');
  const [dueDateError, setDueDateError] = useState('');

  /**
   * Effect to initialize form fields when the task prop changes
   */
  useEffect(() => {
    if (task) {
      setTitle(task.title || '');
      setDescription(task.description || '');
      setAssignee(task.assigneeENS || task.assignee || '');
      setDueDate(task.dueDate ? new Date(task.dueDate).toISOString().split('T')[0] : '');
      setStatus(task.status || 'todo');
      setPriority(task.priority || 'medium');
      setLoe(task.loe || 'medium');
      setEstimatedHours(task.estimatedHours?.toString() || '');
      setTags(task.tags || []);
    } else {
      // Reset form for new task
      setTitle('');
      setDescription('');
      setAssignee('');
      setDueDate('');
      setStatus('todo');
      setPriority('medium');
      setLoe('medium');
      setEstimatedHours('');
      setTags([]);
    }
    // Reset error states
    setTitleError('');
    setAssigneeError('');
    setDueDateError('');
  }, [task]);

  /**
   * Validates the assignee input (Ethereum address or ENS name)
   * @param {string} value - The assignee input value
   * @returns {Promise<boolean>} - Whether the input is valid
   */
  const validateAssignee = useCallback(async (value) => {
    if (utils.isAddress(value)) {
      setAssigneeError('');
      return true;
    }
    if (provider) {
      try {
        const address = await provider.resolveName(value);
        if (address) {
          setAssigneeError('');
          return true;
        }
      } catch (error) {
        console.error("Error resolving ENS name:", error);
      }
    }
    setAssigneeError('Invalid Ethereum address or ENS name');
    return false;
  }, [provider]);

  /**
   * Handles form submission
   * Validates inputs and calls onSave with the task data
   */
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    
    // Validate title
    if (!title.trim()) {
      setTitleError('Title is required');
      return;
    }

    // Validate assignee
    if (!await validateAssignee(assignee)) {
      return;
    }

    // Validate due date
    if (!dueDate) {
      setDueDateError('Due date is required');
      return;
    }

    const taskToSave = {
      id: task ? task.id : null,
      title: title.trim(),
      description: description.trim(),
      assignee,
      dueDate: new Date(dueDate).toISOString(),
      status,
      priority,
      loe,
      estimatedHours: parseFloat(estimatedHours) || 0,
      tags,
      creator: walletAddress
    };

    onSave(taskToSave);
    onClose();
  }, [title, description, assignee, dueDate, status, priority, loe, estimatedHours, tags, task, walletAddress, onSave, onClose, validateAssignee]);

  /**
   * Adds a new tag to the task
   */
  const handleAddTag = useCallback(() => {
    if (newTag && !tags.includes(newTag)) {
      setTags(prevTags => [...prevTags, newTag]);
      setNewTag('');
    }
  }, [newTag, tags]);

  /**
   * Removes a tag from the task
   * @param {string} tagToDelete - The tag to be removed
   */
  const handleDeleteTag = useCallback((tagToDelete) => {
    setTags(prevTags => prevTags.filter(tag => tag !== tagToDelete));
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{task ? 'Edit Task' : 'Create New Task'}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={!!titleError}
            helperText={titleError}
            required
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Assignee (Address or ENS)"
            type="text"
            fullWidth
            value={assignee}
            onChange={(e) => setAssignee(e.target.value)}
            error={!!assigneeError}
            helperText={assigneeError}
            onBlur={() => validateAssignee(assignee)}
            required
          />
          <TextField
            margin="dense"
            label="Due Date"
            type="date"
            fullWidth
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!dueDateError}
            helperText={dueDateError}
            required
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="todo">To Do</MenuItem>
              <MenuItem value="in-progress">In Progress</MenuItem>
              <MenuItem value="done">Done</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Priority</InputLabel>
            <Select
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
              label="Priority"
            >
              <MenuItem value="low">Low</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="high">High</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Level of Effort (LoE)</InputLabel>
            <Select
              value={loe}
              onChange={(e) => setLoe(e.target.value)}
              label="Level of Effort (LoE)"
            >
              <MenuItem value="low">Low</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="high">High</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Estimated Hours"
            type="number"
            fullWidth
            value={estimatedHours}
            onChange={(e) => setEstimatedHours(e.target.value)}
            inputProps={{ min: "0", step: "0.5" }}
          />
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Tags</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => handleDeleteTag(tag)}
                />
              ))}
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
              <TextField
                label="New Tag"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                size="small"
              />
              <Button onClick={handleAddTag} sx={{ ml: 1 }}>
                Add Tag
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TaskForm;