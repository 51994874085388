import React, { useState, useEffect, useCallback } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_ID
    }
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Coinban",
      infuraId: process.env.REACT_APP_INFURA_ID
    }
  }
};

const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  providerOptions
});

const WalletConnect = ({ onWalletConnect, onWalletDisconnect, walletAddress }) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, []);

  const connectWallet = useCallback(async () => {
    setIsConnecting(true);
    setError(null);

    try {
      const instance = await web3Modal.connect();
      const address = instance.selectedAddress || instance.accounts[0];
      
      if (!address) {
        throw new Error('No address found');
      }

      onWalletConnect(address, instance);

      instance.on("accountsChanged", (accounts) => {
        onWalletConnect(accounts[0], instance);
      });

      instance.on("chainChanged", () => {
        window.location.reload();
      });

      instance.on("disconnect", () => {
        disconnectWallet();
      });

    } catch (err) {
      console.error('Failed to connect wallet:', err);
      setError('Failed to connect wallet. Please try again.');
    } finally {
      setIsConnecting(false);
    }
  }, [onWalletConnect]);

  const disconnectWallet = useCallback(async () => {
    if (web3Modal.cachedProvider) {
      await web3Modal.clearCachedProvider();
    }
    onWalletDisconnect();
  }, [onWalletDisconnect]);

  return (
    <Box>
      {walletAddress ? (
        <Box>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Connected: {walletAddress.slice(0, 6)}...{walletAddress.slice(-4)}
          </Typography>
          <Button variant="outlined" color="primary" onClick={disconnectWallet}>
            Disconnect Wallet
          </Button>
        </Box>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={connectWallet}
          disabled={isConnecting}
        >
          {isConnecting ? <CircularProgress size={24} /> : 'Connect Wallet'}
        </Button>
      )}
      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default WalletConnect;