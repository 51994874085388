import React, { useState, useCallback } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Container, Box, Typography, AppBar, Toolbar, Link } from '@mui/material';
import WalletConnect from './components/WalletConnect';
import KanbanBoard from './components/KanbanBoard';
import { ethers } from 'ethers';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    primary: {
      main: '#90caf9',
    },
  },
});

function App() {
  const [walletAddress, setWalletAddress] = useState('');
  const [provider, setProvider] = useState(null);

  const handleWalletConnect = useCallback((address, web3Provider) => {
    setWalletAddress(address);
    const provider = new ethers.providers.Web3Provider(web3Provider);
    setProvider(provider);
  }, []);

  const handleWalletDisconnect = useCallback(() => {
    setWalletAddress('');
    setProvider(null);
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <AppBar position="static" sx={{ bgcolor: '#333333' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              <img src="/Logo600.png" alt="Logo" style={{ height: '30px', marginRight: '16px' }} />
              <Typography variant="h6" component="div">
                Koinban
              </Typography>
            </Box>
            <WalletConnect
              onWalletConnect={handleWalletConnect}
              onWalletDisconnect={handleWalletDisconnect}
              walletAddress={walletAddress}
            />
          </Toolbar>
        </AppBar>
        <Container component="main" maxWidth="lg" sx={{ mt: 4, flexGrow: 1 }}>
          {walletAddress ? (
            <KanbanBoard provider={provider} walletAddress={walletAddress} />
          ) : (
            <Box sx={{ textAlign: 'center', mt: 8 }}>
              <Typography variant="h4" gutterBottom>
                Welcome to Koinban
              </Typography>
              <Typography variant="body1">
                Please connect your wallet to start managing your tasks.
              </Typography>
            </Box>
          )}
        </Container>
        <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: '#333333' }}>
          <Container maxWidth="sm">
            <Typography variant="body2" color="text.secondary" align="center">
              © {new Date().getFullYear()} Koinban. All rights reserved.
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
              Donate: <Link href="https://explorer.solana.com/address/96vzFiKm2pwTEjdgDD1iTGSLjzwbiRBe5RtihqKadG33" target="_blank" rel="noopener noreferrer">
                SOL 96vzFiKm2pwTEjdgDD1iTGSLjzwbiRBe5RtihqKadG33
              </Link>
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;