import React from 'react';
import { Paper, Typography, Tooltip } from '@mui/material';

/**
 * TaskCard component for displaying individual tasks
 * @param {Object} props - Component props
 * @param {Object} props.task - Task object containing task details
 * @param {Function} props.onEditTask - Function to handle task editing
 */
const TaskCard = ({ task, onEditTask }) => {
  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', task.id);
  };

  /**
   * Render an address or ENS name with a tooltip
   * @param {string} address - Ethereum address
   * @param {string} ens - ENS name (if available)
   * @returns {JSX.Element} Tooltip wrapped Typography component
   */
  const renderAddressOrENS = (address, ens) => (
    <Tooltip title={address} arrow placement="top">
      <Typography variant="body2" align="left" sx={{ wordBreak: 'break-word' }}>
        {ens || address}
      </Typography>
    </Tooltip>
  );

  return (
    <Paper
      draggable
      onDragStart={handleDragStart}
      elevation={3}
      sx={{
        p: 2,
        mb: 2,
        bgcolor: '#2a2a2a',
        '&:hover': {
          bgcolor: '#3a3a3a',
        },
        cursor: 'move',
      }}
      onClick={() => onEditTask(task)}
    >
      <Typography variant="h6" gutterBottom align="left">
        {task.title}
      </Typography>
      <Typography variant="body2" align="left">
        Description: {task.description}
      </Typography>
      <Typography variant="body2" align="left">
        Assignee: {renderAddressOrENS(task.assignee, task.assigneeENS)}
      </Typography>
      <Typography variant="body2" align="left">
        Creator: {renderAddressOrENS(task.creator, task.creatorENS)}
      </Typography>
      <Typography variant="body2" align="left">
        Due Date: {new Date(task.dueDate).toLocaleDateString()}
      </Typography>
    </Paper>
  );
};

export default TaskCard;